import { render } from '@testing-library/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearEdit } from '../../../features/InfoSlice/infoSlice';


const UpdateModal = ({ updateCustomer, setUpdateCustomer, refetchData, pageNo }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    // const Supplier = useSelector((state) => state.info.Supplier[0]);
    const { _id, name, code, address, phone, opening, status } = updateCustomer;
    const [id, setId] = useState('');


    function guardarArchivo(e) {
        var file = e.target.files[0] //the file
        var reader = new FileReader() //this for convert to Base64 
        reader.readAsDataURL(e.target.files[0]) //start conversion...
        reader.onload = function (e) { //.. once finished..
            var rawLog = reader.result.split(',')[1]; //extract only thee file data part
            var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
            fetch('https://script.google.com/macros/s/AKfycbwYKgy85mYgpiT7U3mV2T6vum0yKg_I-AAJegLuNfeosEO_HligOW_ic_M1mW8pUqk/exec', //your AppsScript URL
                { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                .then(res => res.json()).then((a) => {
                    if (a.id) {
                        toast.success("ছবি আপলোড হয়েছে")
                        setId(a.id)

                    }
                }).catch(e => console.log(e)) // Or Error in console
        }
    }

    const onSubmit = (data) => {
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        // const isImg = updateCustomer?.img ? updateCustomer?.img : '';
        const link = `https://docs.google.com/uc?export=download&id=${id}`
        // const img = id ? link : isImgF

        const requestBody = id
            ? { user, code, ...data, img: link, approvedBy }
            : { user, code, ...data, approvedBy };

        fetch(`https://servers.clearsoftwares.net/jibon-traders/getmaincustomer/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setUpdateCustomer(null);
                toast('Customer Upadated Successful');
                refetchData(pageNo);
            })
    }

    const noEdit = () => {
        window.location.reload(false)
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-md font-bold">Update-{name}</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Customer Code</span>
                            </label>
                            <input {...register("code")} type="text" value={code} placeholder="Type Customer Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Customer Name</span>
                            </label>
                            <input {...register("name")} type="text" defaultValue={name} placeholder="Type Customer Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Address</span>
                            </label>
                            <input {...register("address")} type="text" defaultValue={address} placeholder="Type Address Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Phone No</span>
                            </label>
                            <input {...register("phone")} type="text" defaultValue={phone} placeholder="Type Phone No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Opening Balance</span>
                            </label>
                            <input {...register("opening")} type="text" defaultValue={opening} placeholder="Opening Balance" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text">ছবির ফাইল</span>
                            </label>
                            <input type="file" id="customFile" placeholder="ইমেজ ফাইল" onChange={(e) => guardarArchivo(e)} />
                        </div>
                        <div class="form-control w-80 max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Customer' className='btn btn-sm bg-red-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;