import logo from '../../assets/images/logo.png'

const BusinessInfo = {
    name: "Jibon Traders",
    fullName: "M/S Jibon Traders",
    dealer: "(Seller of Medicine and Fertilizer of Crops)",
    address: "Vulli Bazar, Thakurgaon",
    mobile: "Mobile: 01744-324255,01889-972825",
    img: logo,
}

export default BusinessInfo;
