import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';


const UpdateModal = ({ updateLoanReturn, setUpdateLoanReturn,fetchData }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const { _id, date, code, name, invoice, amount, purpose, status } = updateLoanReturn;
    const [isLoanAccountSearching, setIsLoanAccountSearching] = useState(false);
    const [loanAccountText, setLoanAccountText] = useState([]);
    const [loanAccountSearch, setLoanAccountSearch] = useState([]);
    const [currentLoanAccount, setCurrentLoanAccount] = useState([]);
    const [isSelectLoanAccount, setIsSelectLoanAccount] = useState(false);

    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/jibon-traders/getloanaccounts')
            .then(res => res.json())
            .then(data => {
                setAccounts(data);
                setLoading(false)
            })
    }, [])

    const onSubmit = (data) => {
        const name = currentLoanAccount?.name;
        const code = currentLoanAccount?.code;
        const loanreturn = parseInt(data.amount);
        const user = userAc?.email.split('@')[0];
        const approvedBy = false;
        const newData = { ...data, name, code, loanreturn, user, approvedBy }
        fetch(`https://servers.clearsoftwares.net/jibon-traders/getloanreturn/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => {
                setUpdateLoanReturn(null);
                toast('Loan Return Upadated Successful');
                fetchData();
            })
    }

    const noEdit = () => {
        window.location.reload(false);
    }

    if (loading) {
        return <Loader />
    }

    const handleSearchLoanAccount = (event) => {
        setLoanAccountText(event.target.value)
        const match = accounts.filter(loanAccount => loanAccount.name.toLowerCase().includes(loanAccountText)
            || loanAccount.name.includes(loanAccountText) || loanAccount.name.toUpperCase().includes(loanAccountText));
        setLoanAccountSearch(match);
        setIsLoanAccountSearching(true);
    }

    const handleSelectLoanAccount = (loanAccount, event) => {
        event.preventDefault();
        event.stopPropagation();
        const opening = parseFloat(loanAccount?.opening)
        const url = `https://servers.clearsoftwares.net/jibon-traders/filteredlaonaccount?code=${loanAccount.code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const balance = opening + data?.defaultTotalLoanTaken - data?.defaultTotalLoanReturn;
                setCurrentLoanAccount({ ...loanAccount, balance });
                setIsSelectLoanAccount(true)
            })
        setIsLoanAccountSearching(false);
        setLoanAccountText('');
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12">
                <div class="modal-box relative text-xs">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update-{code}. {name}</h3>
                    <div class="form-control w-full max-w-xs mt-5 col-span-2 mx-auto">
                        <div class="form-control w-full">
                            <input onChange={handleSearchLoanAccount} value={loanAccountText} type="text" placeholder="Search Loan Account" class="rounded-lg bg-red-300 p-3 placeholder-black  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                        </div>
                        <div style={isLoanAccountSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isLoanAccountSearching ? 'h-16' : "h-8"} w-full overflow-auto`}>
                            {
                                isLoanAccountSearching ? loanAccountSearch.map(loanAccount =>
                                    <label for="update-modal" onClick={event => handleSelectLoanAccount(loanAccount, event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{loanAccount.code}. {loanAccount?.name}</label>) :
                                    accounts.map(loanAccount =>
                                        <label for="update-modal" onClick={event => handleSelectLoanAccount(loanAccount, event)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{loanAccount.code}. {loanAccount?.name}</label>)
                            }
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 gap-4 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Select Date</span>
                            </label>
                            <input {...register("date")} type="date" defaultValue={date} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Loan Account Name</span>
                            </label>
                            <input  {...register("name")} type="text" value={!isSelectLoanAccount ? name : currentLoanAccount?.name || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Account Code</span>
                            </label>
                            <input  {...register("code")} type="text" value={!isSelectLoanAccount ? code : currentLoanAccount?.code || ''} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Invoice No</span>
                            </label>
                            <input {...register("invoice")} type="text" defaultValue={invoice} placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Amount</span>
                            </label>
                            <input {...register("amount")} type="number" defaultValue={amount} placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Purpose</span>
                            </label>
                            <input {...register("purpose")} type="text" defaultValue={purpose} placeholder="Type Loan Return Purpose Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option >Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Loan Return' className='btn bg-red-600 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;